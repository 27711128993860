import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src1524589205/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Getting Started`}</h1>
    <p>{`The following image shows an overview of your journey with Fidel API to using real-time transaction data to provide your users a seamless experience:`}</p>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/customer-journey.png",
        "alt": null
      }}></img></p>
    <p>{`In a high-level overview, you will go through the following steps:`}</p>
    <ol>
      <li parentName="ol">{`Create a Fidel API account and explore the platform.`}</li>
      <li parentName="ol">{`Validate the feasibility of the solution with Fidel API's Sales team and sign the contract.`}</li>
      <li parentName="ol">{`Document your use case and your application for the card network's approval.`}</li>
      <li parentName="ol">{`Integrate your application with Fidel API's platform.`}</li>
      <li parentName="ol">{`Test your application with real transactions of test users.`}</li>
      <li parentName="ol">{`Launch your application to your audience.`}</li>
    </ol>
    <p>{`This page describes how to get started with the first step: testing the platform in the sandbox environment.`}</p>
    <h2>{`Creating an Account`}</h2>
    <p>{`To get an understanding of the platform and its object models, `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk/sign-up"
      }}>{`create a Fidel API account`}</a>{`. This account will give you access to the `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk"
      }}>{`Fidel API dashboard`}</a>{`, where you can configure your card-linked program and test how transactions are received.`}</p>
    <h2>{`Navigating on the Dashboard`}</h2>
    <p>{`The Fidel API dashboard is based on the following concepts:`}</p>
    <ul>
      <li parentName="ul">{`programs`}</li>
      <li parentName="ul">{`cards`}</li>
      <li parentName="ul">{`transactions`}</li>
      <li parentName="ul">{`webhooks`}</li>
    </ul>
    <p><strong parentName="p">{`Programs`}</strong>{` are used to group cards and transactions. `}<strong parentName="p">{`Webhooks`}</strong>{` allow you to react to transaction events of a certain program.`}</p>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/programs.png",
        "alt": "Programs in the Fidel API dashboard",
        "title": "Programs in the Fidel API dashboard"
      }}></img></p>
    <p>{`Note that the pages of `}<strong parentName="p">{`Brands`}</strong>{` and `}<strong parentName="p">{`Offers`}</strong>{` don’t play any role in the Transaction Stream API, they apply only to the Select Transactions API.`}</p>
    <p>{`In each program you can see the cards, transactions and webhooks that belong to that program.`}</p>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/transactions-stream-highlight.png",
        "alt": "Cards, transactions and webhooks of a program",
        "title": "Cards, transactions and webhooks of a program"
      }}></img></p>
    <h3>{`Account Settings`}</h3>
    <p>{`You can manage your Fidel API account in the `}<strong parentName="p">{`Account settings`}</strong>{` page. To navigate there, open the drop-down menu next to your account name.`}</p>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/account-settings-navigation.png",
        "alt": null
      }}></img></p>
    <p>{`Under `}<strong parentName="p">{`Account settings`}</strong>{`, you can find the following information:`}</p>
    <ul>
      <li parentName="ul">{`API version, developer keys`}</li>
      <li parentName="ul">{`Billing information (billing period, billing history)`}</li>
      <li parentName="ul">{`Users (inviting users, granting privileges)`}</li>
      <li parentName="ul">{`Security settings (password, card number obfuscation settings, etc.)`}</li>
    </ul>
    <h2>{`Configuring Your Account`}</h2>
    <p>{`This section walks you through creating a program and adding users to your account.`}</p>
    <ol>
      <li parentName="ol">{`When you first log in, you have a Demo Program created for you. However, that program is based on the Select Transactions API.`}<br parentName="li"></br>
        {`To start exploring the Transaction Stream API instead, use the `}<strong parentName="li">{`New program`}</strong>{` button in the top right corner to create a new program. Select `}<inlineCode parentName="li">{`Transaction Stream`}</inlineCode>{` as the program type.`}</li>
      <li parentName="ol">{`To add users, navigate to the `}<strong parentName="li">{`Account settings`}</strong>{` page. You can find this option in the drop-down menu next to your account name. Then create the users:`}
        <ol parentName="li">
          <li parentName="ol">{`Select the `}<strong parentName="li">{`Users`}</strong>{` tab and click on `}<strong parentName="li">{`Invite users`}</strong>{`.`}</li>
          <li parentName="ol">{`Type the email address of the user, and select an account type (admin or developer).`}</li>
          <li parentName="ol">{`Click on the `}<strong parentName="li">{`Invite`}</strong>{` button.`}<br parentName="li"></br>
            {`The user will receive an email with a link to sign up for a user account in your environment.`}</li>
        </ol>
      </li>
    </ol>
    <h2>{`Testing Cards and Transactions`}</h2>
    <p>{`In test environments, you cannot use real-life cards and transactions. This is why you need to test the platform with Fidel API’s test cards. You can do so in the Playground page of the Fidel API dashboard. Read the `}<a parentName="p" {...{
        "href": "/stream/getting-started#configuring-your-account"
      }}>{`Configuring Your Account`}</a>{` section above to understand how.`}</p>
    <p>{`Developers can also use the API endpoints and the Fidel API SDKs to link test cards to programs in the platform, and to simulate incoming transactions. Read the `}<a parentName="p" {...{
        "href": "/stream/getting-started#for-developers"
      }}>{`For Developers`}</a>{` section to learn how.`}</p>
    <p>{`The following sections walk you through linking a test card and creating a test transaction in the dashboard. It also shows how to clear test transactions.`}</p>
    <h3>{`Linking a Test Card`}</h3>
    <p>{`See how card linking and verification works by linking a test card to your program:`}</p>
    <ol>
      <li parentName="ol">{`Navigate to the `}<strong parentName="li">{`Cards`}</strong>{` page, click the `}<strong parentName="li">{`New card`}</strong>{` button.`}</li>
      <li parentName="ol">{`Read through the information about the card verification, and select `}<strong parentName="li">{`Continue`}</strong>{` to start linking a test card.`}</li>
      <li parentName="ol">{`Fidel API provides a set of test cards that you can use to explore the product (only in the Fidel API platform, and only in test mode).`}<br parentName="li"></br>
        {`To link a test card of Fidel API, add the following card details. For other test card numbers, check the `}<a parentName="li" {...{
          "href": "/stream/getting-started#test-card-numbers"
        }}>{`Test Card Numbers`}</a>{` section.`}
        <ul parentName="li">
          <li parentName="ul">{`Card number: `}<inlineCode parentName="li">{`4444000000004000`}</inlineCode></li>
          <li parentName="ul">{`Expiry date: any date in the future`}</li>
          <li parentName="ul">{`Country of issue: United States`}</li>
        </ul>
      </li>
    </ol>
    <img src="https://docs.fidel.uk/assets/images/stream-card-linking-form.png" srcSet="https://docs.fidel.uk/assets/images/stream-card-linking-form.png, https://docs.fidel.uk/assets/images/stream-card-linking-form@2x.png 2x" alt="Stream Card-Linking Form" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Mark the checkbox to accept the terms and conditions, and click `}<strong parentName="li">{`Next`}</strong>{`.`}</li>
      <li parentName="ol">{`To verify the card, type `}<inlineCode parentName="li">{`0.67`}</inlineCode>{` as the amount of the microcharge, and click `}<strong parentName="li">{`Verify`}</strong>{`.`}<br parentName="li"></br>
        {`(In a real-life scenario, users get this value by accessing their bank account and checking the value of the microcharge from “Card Verification”. The microcharge should appear on the user’s account within a few minutes.)`}</li>
    </ol>
    <img src="https://docs.fidel.uk/assets/images/microcharge.png" srcSet="https://docs.fidel.uk/assets/images/microcharge.png, https://docs.fidel.uk/assets/images/microcharge@2x.png 2x" alt="Microcharge" />
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`After the successful card verification, the card will be displayed on the Cards page:`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/cards-create-stream-4.png",
        "alt": null
      }}></img></p>
    <h3>{`Creating a Test Transaction`}</h3>
    <p>{`To see how transactions look like in the Fidel API platform, complete the following steps:`}</p>
    <ol>
      <li parentName="ol">{`Navigate to the `}<a parentName="li" {...{
          "href": "https://dashboard.fidel.uk/playground"
        }}>{`Playground`}</a>{`.`}</li>
      <li parentName="ol">{`In the drop-down box, select `}<inlineCode parentName="li">{`Transaction Stream`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Click on `}<strong parentName="li">{`Create Transaction`}</strong>{`.`}</li>
      <li parentName="ol">{`Select the `}<strong parentName="li">{`Program`}</strong>{`.`}</li>
      <li parentName="ol">{`Select the `}<strong parentName="li">{`Card`}</strong>{` you created previously.`}</li>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Run`}</strong>{` and a test transaction will be created.`}</li>
      <li parentName="ol">{`Check the transactions you just created:`}
        <ol parentName="li">
          <li parentName="ol">{`Navigate to `}<strong parentName="li">{`Programs`}</strong>{` and select your program.`}</li>
          <li parentName="ol">{`Click `}<strong parentName="li">{`Transactions`}</strong>{`.`}</li>
          <li parentName="ol">{`Observe the list of transactions you created in the previous step.`}</li>
          <li parentName="ol">{`Click on one of the transactions and observe the transaction details.`}<br parentName="li"></br>
            {`You can read about each data field `}<a parentName="li" {...{
              "href": "/stream/#collected-data"
            }}>{`here`}</a>{`.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Close the `}<strong parentName="li">{`Transaction details`}</strong>{` section to go back to the transaction list.`}<br parentName="li"></br>
        {`As you can see there, new test transactions are waiting for authorization. Simulate the clearing by selecting the `}<strong parentName="li">{`…`}</strong>{` menu and then clicking `}<strong parentName="li">{`Clear`}</strong>{`.`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/transactions-more-button.png",
        "alt": null
      }}></img></p>
    <h2>{`Test Card Numbers`}</h2>
    <p>{`For security purposes, the Fidel API test environment doesn't accept live card numbers. Fidel API has a range of test card numbers you can use while integrating or testing the API: `}<inlineCode parentName="p">{`4444000000004***`}</inlineCode>{` where `}<inlineCode parentName="p">{`*`}</inlineCode>{` can be any digit. For example, `}<inlineCode parentName="p">{`4444000000004278`}</inlineCode>{` is a valid test card number. The test card numbers work in the `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk/playground"
      }}>{`Playground`}</a>{`, with the Fidel API SDKs and with the Cards APIs.`}</p>
    <p>{`When linking a Fidel API test card, type in `}<inlineCode parentName="p">{`$0.67`}</inlineCode>{` for verification.`}</p>
    <h2>{`For Developers`}</h2>
    <h3>{`Get API keys`}</h3>
    <p>{`On the `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk/account"
      }}>{`Account page`}</a>{` you will find two SDK and two API keys. The SDK keys are used to link the web and mobile SDKs to your account and the API keys enable you to access the API endpoints directly. Use the test keys to access the `}<a parentName="p" {...{
        "href": "/stream/test-and-live-modes/#test-mode"
      }}>{`test environment`}</a>{` data, and live keys to access the `}<a parentName="p" {...{
        "href": "/stream/test-and-live-modes/#live-mode"
      }}>{`live environment`}</a>{` data.`}</p>
    <p>{`Note that due to security reasons, `}<strong parentName="p">{`the secret API key should not be used in a mobile application or a front-end web app`}</strong>{`.`}</p>
    <p>{`When you're ready to `}<a parentName="p" {...{
        "href": "/stream/test-and-live-modes#the-go-live-process"
      }}>{`go live`}</a>{` with your integration and test with credit/debit cards, you can request access to the live keys by clicking the `}<strong parentName="p">{`Upgrade to go live`}</strong>{` button at the bottom left of the dashboard.`}</p>
    <h3>{`Create a Test Transaction with the API`}</h3>
    <p>{`If you have a program and a card linked to it, you can use the `}<inlineCode parentName="p">{`/transactions/test `}</inlineCode>{`endpoint to create a test transaction.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl -X POST \\
  https://api.fidel.uk/v1/transactions/test \\
  -H 'content-type: application/json' \\
  -H 'fidel-key: sk_test_**************************' \\
  -d '{
    "amount": 10,
    "cardId": "eb54205c-5827-47cf-8444-1e2f125837e4",
    "currency": "USD"
    }'
`}</code></pre>
    <h3>{`Install SDKs`}</h3>
    <p>{`You can use the web and mobile SDKs to link your user's card to your program without having to implement additional security measures on your server-side code. The `}<a parentName="p" {...{
        "href": "doc:tutorial"
      }}>{`tutorial`}</a>{` walks you through the necessary steps to start using the APIs and SDKs.`}</p>
    <p>{`Learn how to use the `}<a parentName="p" {...{
        "href": "/stream/sdks/web/v3"
      }}>{`SDKs`}</a>{` in your applications, how to customize the UI and capture card details securely on your website or mobile applications.`}</p>
    <p>{`Check the `}<a parentName="p" {...{
        "href": "/stream/programs"
      }}>{`Programs`}</a>{`, `}<a parentName="p" {...{
        "href": "/stream/cards"
      }}>{`Cards`}</a>{`, `}<a parentName="p" {...{
        "href": "/stream/transactions"
      }}>{`Transactions`}</a>{` and `}<a parentName="p" {...{
        "href": "/stream/webhooks"
      }}>{`Webhooks`}</a>{` sections to get an overview of the Transaction Stream API object structure.`}</p>
    <h2>{`Learn More`}</h2>
    <ul>
      <li parentName="ul">{`Read more about the objects of the platform: `}<a parentName="li" {...{
          "href": "/stream/programs"
        }}>{`Programs`}</a>{`, `}<a parentName="li" {...{
          "href": "/stream/cards"
        }}>{`Cards`}</a>{`, `}<a parentName="li" {...{
          "href": "/stream/transactions"
        }}>{`Transactions`}</a>{` and `}<a parentName="li" {...{
          "href": "/stream/webhooks"
        }}>{`Webhooks`}</a></li>
      <li parentName="ul">{`Walk through our `}<a parentName="li" {...{
          "href": "/stream/tutorials/card-linking"
        }}>{`tutorial`}</a>{` to start using the APIs and SDKs`}</li>
      <li parentName="ul">{`Learn about the `}<a parentName="li" {...{
          "href": "/stream/sdks/web/v3"
        }}>{`SDKs`}</a></li>
      <li parentName="ul">{`Check out the `}<a parentName="li" {...{
          "href": "https://transaction-stream.fidel.uk/reference/introduction-1"
        }}>{`API reference docs`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      